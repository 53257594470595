<template>
    <div>
        <el-form
            v-loading="dataLoading"
            :model="formData"
            :rules="rules"
            ref="formData"
            label-position="top"
            size="medium"
        >
            <div class="page-group-title"><span>基本信息</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="name" label="商家名称:">
                        <el-input v-model.trim="formData.name" placeholder="请输入商家名称" maxlength="200"></el-input>
                    </el-form-item>
                </el-col>
                <el-col v-if="id" :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="status" label="状态:">
                        <el-radio-group v-model="formData.status">
                            <el-radio :label="1">正常</el-radio>
                            <el-radio :label="-1">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="linkman" label="联系人姓名:">
                        <el-input v-model.trim="formData.linkman" placeholder="请输入联系人姓名" maxlength="200"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="telephone" label="联系电话:">
                        <el-input v-model.trim="formData.telephone" placeholder="请输入联系电话" maxlength="11"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="area" label="所在地区:">
                        <el-cascader v-model="formData.area" :options="areaList" :props="areaSeting" @change="changeArea"></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="address" label="详细地址:">
                        <el-input v-model.trim="formData.address" placeholder="请输入详细地址" maxlength="200"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>商家图片</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="photoList">
                        <image-manage :image-list.sync="formData.photoList" @onUploadSuccess="updatePhotoList"></image-manage>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>商家证件图片</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="licenseList">
                        <image-manage :image-list.sync="formData.licenseList" ></image-manage>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>商家详情</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="description" :show-message="formData.description ? false : true">
                        <tinymce v-if="reFresh" v-model="formData.description" :height="500" class="MT10"></tinymce>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-divider></el-divider>
        <div class="form-btns">
            <el-button @click="goBack">返回</el-button>
            <el-button type="primary"  @click="onSubmit" :loading="btnLoading">保存</el-button>
        </div>
    </div>
</template>
<script>
import Tinymce from '@/components/Tinymce'; 
import ImageManage from '@/components/ImageManage';
import {allarea} from '@/assets/js/commonApi.js';
const defaultFormData = {
    address: "",
    cityCode: "",
    countyCode: "",
    description: "",
    licenseList: [],
    linkman: "",
    name: "",
    photoList: [],
    provinceCode: "",
    status: 1,
    telephone: "",
    area:[]
}
export default {
    props:{
        id:{
            type: [String, Number],
            default: ''
        }
    },
    components:{Tinymce,ImageManage},
    data(){
        return{
            redirect: this.$route.query.redirect || '/biz/list',
            btnLoading: false,
            dataLoading: false,
            formData: {...defaultFormData},
            rules:{
                name: [{required: true, message: '请输入名称'}],
                linkman: [{required: true, message: '请输入联系人姓名'}],
                telephone: [{required: true, message: '请输入联系电话'},{ validator: this.$utils.validatePhone }],
                area: [{type: 'array', required: true, message: '请选择所在区域'}],
                address: [{required: true, message: '请填写详细地址'}],
                photoList: [{type: 'array', required: true, message: '请上传商家图片'}],
            },
            reFresh:false,
            areaList:[],
            areaSeting: {
                value: "code",
                label: "name",
                children: "child"
            }
        }
    },
    created(){
        this.getAllArea();
    },
    mounted(){
        if( this.id ){
            this.getDataDetail();
        }else{
            this.reFresh = true;
        }
    },
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //地域信息，省、市、县，树形结构
        async getAllArea(){
            const res = await allarea();
            const {state,result} = res.data;
            if(state && result){
                this.areaList = [...result];
            }
        },
        //选择区域
        changeArea(e){
            this.formData.provinceCode = e[0];
            this.formData.cityCode = e[1];
            this.formData.countyCode = e[2];
        },
        //上传图片成功后
        updatePhotoList(){
            this.$refs["formData"].clearValidate('photoList');
        },
        //获取编辑详情
        getDataDetail(){
            this.dataLoading = true;
            this.$request({
                url: '/api/ht/biz/info',
                method: "POST",
                params: {businessId: this.id},
            })
                .then((res) => {
                    //处理成功回调
                    const { state, errmsg, result } = res.data;
                    if (state && state == 1) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            area: [result.provinceCode || '',result.cityCode || '', result.countyCode || '']
                        }
                    } else {
                        return this.$message.error(errmsg || "获取详情失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取详情失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                    this.reFresh = true;
                });
        },
        onSubmit(){
            this.$refs["formData"].validate((valid) => {
                const _url = this.id ? '/api/ht/biz/edit' : '/api/ht/biz/add'
                if (valid) {
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        method: "POST",
                        data: {
                            ...this.formData,
                        },
                    })
                        .then((res) => {
                            //处理成功回调
                            const { state, msg } = res.data;
                            if (state && state == 1) {
                                this.goBack();
                            } else {
                                return this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            });
        }
    }
}
</script>